import { useEffect, useState } from "react";
import Cookies from "js-cookie"
import ServerLoading from "../../Public/ServerLoading";
import ClassConversation from "./components/ClassConversation";
import ClassRoom from "./components/ClassRoom";
import WhatsAppIcon from "../../../../assets/img/whatsapp.png"
import Button from "../../Public/Button";


const Class = (props) => {
    const [s404] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [userClass, setClasses] = useState(false)
    const [showClassRoom, setShowClassRoom] = useState(true)
    const [userDetails, setUserDetails] = useState("")
    
    const [convData, setConvData] = useState({
        id: "",
        name: "",
        special: "",
        draft: ""
    })
    const [msgs, setMsgs] = useState(false)

    const student_id = Cookies.get("mdjdiowd")||""

    const course = userDetails ? userDetails.course : ""


    useEffect(() => {
        if (student_id) {
            const student_classes = () => {
                const storedData = sessionStorage.getItem('student_classes');
                try {
                    if (storedData) {
                        const parsedData = JSON.parse(storedData);
                        const jsonArr = parsedData
                        const sortArrDs = jsonArr.sort((a, b) => b.update_time - a.update_time)
                        setClasses(sortArrDs);
                    }
                } catch {
                    
                }
            }
            const student_class_msg = () => {
                const storedData = sessionStorage.getItem('student_class_msg');
                try {
                    if (storedData) {
                        const parsedData = JSON.parse(storedData);
                        const jsonArr = parsedData
                        const sortArrAs = jsonArr.sort((a, b) => a.date - b.date)
                        setMsgs(sortArrAs);
                    }
                } catch {
                    
                }
            }

            try {
                setUserDetails(JSON.parse(Cookies.get("userDetails")))
            } catch {
                setUserDetails("")
            }
    
            student_classes()
            student_class_msg()

            const intervalId = setInterval(()=> {
                student_classes()
                student_class_msg()
            }, 900);
    
            return () => {
              clearInterval(intervalId);
            };
        }
    },[student_id])

    

    const showClassConv = (id, name, special) => {
        setShowClassRoom(true)
        setConvData({
            id: id,
            name: name,
            special: special,
            draft: sessionStorage.getItem("draft_"+id)
        })
    }

    return (
        <div>
            <ServerLoading display={disabled} />
            {
                !s404?
                <div className="class_">
                    <ClassConversation msgs={msgs} classes={userClass} file={props.file} winWidth={props.winWidth} api={props.api} showClassConv={showClassConv} />
                    <div className="bg_img" style={{
                        display: !(props.winWidth<=699.9)?"block":props.winWidth<=699.9&&showClassRoom?"block":"none"
                    }}>
                        <ClassRoom msgs={msgs} api={props.api} data={convData} file={props.file} winWidth={props.winWidth} setShowClassRoom={setShowClassRoom} />
                    </div> 
                </div>:
                <div style={{
                    textAlign: "center",
                    background: "#fff",
                    height: "100%"
                }}>
                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        {
                            course ?
                            <a href="https://chat.whatsapp.com/BgJVp4X3iQ28WHLwAOjRFy" style={{
                                display: "block",
                                background: "#25D366",
                                color: "#fff",
                                paddingTop:"10px",
                                paddingBottom: "10px",
                                paddingLeft: "12px",
                                borderRadius: "8px",
                                fontSize: "13px",
                                width: "fit-content",
                                textDecoration: "none", 
                                textAlign: "center",
                                margin: "5px auto",
                                position: "relative",
                                paddingRight: "50px"
                            }}>
                                Enter General WA Classroom
                                <span style={{
                                    width: "35px",
                                    height: "36px",
                                    overflow: "hidden",
                                    display: "block",
                                    position: "absolute",
                                    right: "7px",
                                    top: "50%",
                                    borderRadius: "35px",
                                    transform: "translate(0%, -50%)"
                                }}>
                                    <img src={WhatsAppIcon} style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }} alt="" />
                                </span>
                            </a> 
                            :
                            <Button name="Couldn't determine department. Please refresh" styles={{
                                display: "block",
                                fontSize: "13px",
                                margin: "5px auto",
                                borderRadius: "8px",
                                cursor: "not-allowed"
                            }} error />
                        }
                        {
                            course ?
                            <a href={
                                course==="Medical Sciences & Life Sciences" || course==="Engineering & Physical Sciences"?
                                "https://chat.whatsapp.com/KvU4Wvfm5IaDQB8afXfuLf"
                                :
                                "https://chat.whatsapp.com/KTVtYPKdm10DBfiNkmDs7L"
                            } style={{
                                display: "block",
                                background: "#25D366",
                                color: "#fff",
                                paddingTop:"10px",
                                paddingBottom: "10px",
                                paddingLeft: "12px",
                                borderRadius: "8px",
                                fontSize: "13px",
                                width: "fit-content",
                                textDecoration: "none", 
                                textAlign: "center",
                                margin: "5px auto",
                                position: "relative",
                                paddingRight: "50px"
                            }}>
                                Enter Departmental WA Classroom
                                <span style={{
                                width: "35px",
                                height: "36px",
                                overflow: "hidden",
                                display: "block",
                                position: "absolute",
                                right: "7px",
                                top: "50%",
                                borderRadius: "35px",
                                transform: "translate(0%, -50%)"
                                }}>
                                    <img src={WhatsAppIcon} style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }} alt="" />
                                </span>
                            </a>
                            :
                            <Button name="Couldn't determine department. Please refresh" styles={{
                                display: "block",
                                fontSize: "13px",
                                margin: "5px auto",
                                borderRadius: "8px",
                                cursor: "not-allowed"
                            }} error />
                        } 
                        {
                            props.paymentPending?
                            <i style={{display: "block", marginTop: "10px", fontSize: "12px", fontStyle: "italic", color: "#333"}}>
                                <b>Note:</b> Sorry you may be temporarily evicted from the classroom, if admin couldn't confirm your payment. Upon confirmation, you will be added.
                                <br/>
                                <br/>
                                Thank you.
                            </i>
                            :
                            ""
                        }
                    </div>
                </div>
            }
            
        </div>
    )
}

export default Class;
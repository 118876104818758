const AppStyle = (props) => {

    const theme = props.theme
    
    const non_color = `
        @media print {
            @page {
                margin: 0;
            }
            body {
                margin: 3.6cm
            }
            .hide_print {
                display: none;
            }
        }

        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Hide the arrows in Firefox */
        input[type='number'] {
        -moz-appearance: textfield;
        }

        html {
            scroll-behavior: smooth;
            background-size: cover;            
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        body {
            margin:0;
            font-size: 18px;
            background: inherit !important;
        }

        * {
            transition: width .3s;
            transition: height .3s;
            transition: background .3s;
            transition: color .3s;
        }

        .primary_text{
            font-family: inherit;
        }

        .btn_ {
            border-radius: 3px;
            padding: 5px 14px;
            font-size: inherit;
            font-family: inherit;
            border: none;
            cursor: pointer;
            transition: all .3s;
            outline: none;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }
        .btn_:hover {
            opacity: .9;
        }
        .btn_:active {
            opacity: .6;
            border: none;
            outline: none;
        }
        .btn_:disabled {
            opacity: .8;
            cursor: not-allowed;
        }

        .container {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
           
        ::-webkit-scrollbar {
            width: 10px;
            border-radius: 12px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 12px;
        }

        .form-control {
            outline: none;
            border: 1px solid;
            box-shadow: none;
        }

        .form-control:focus {
            outline: none;
            box-shadow: none;
        }

        .form-group {
            position: relative;
        }

        .pswd {
            padding-right: 38px;
        }

        .form-group .toggle_btn {
            position: absolute;
            right: 7px;
            border: none;
            top: 30px;
        }

        .basic_container {
            margin: 20px auto;
            padding: 14px 18px;
            overflow-x: hidden;
        }

        .ele_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
            grid-gap: 10px;
        }
        
        .ele_grid>* {
            border: none;
        }

        .sm_ele_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
            grid-gap: 5px;
        }
        
        .sm_ele_grid>* {
            border: none;
        }

        .ssm_ele_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-gap: 2px;
        }
        
        .ssm_ele_grid>* {
            border: none;
        }

        .xsm_ele_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
            grid-gap: 2px;
        }
        
        .xsm_ele_grid>* {
            border: none;
        }


        .h_btn_r {
            position: fixed;
            width: fit-content;
            right: 12px;
            top: 12px;
        }

        .h_btn_r>* {
            margin: 0px 5px;
        }

        .arr_logo>div:first-child {
            float: left;
            margin-right: 5px;
        }

        .big_logo {
            width: 70%;
            height: auto;
        }
        
        .bg_img {
            scroll-behavior: smooth;
            background-size: cover;            
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }


        .auth {
            width: 100%;
            padding: 8px 12px;
            margin: 0px auto;
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
        }

        .auth>div:first-child {
            width: 65%;
            margin-right: 35%;
            border-radius: 5px;
        }

        .auth>div:last-child {
            width: 33%;
            height: 95%;
            margin-right: 1%;
            margin-top: 1.5%;
            border-radius: 5px;
            position: fixed;
            right: 0px;
            top: 0px;
        } 
        
        .auth_holder {
            padding: 18px 16px;
            overflow-y: scroll;
            height: 100%; 
        }
        
        .m {
            display: none;
        }

        .parag {
            padding: 12px;
            text-align: justify;
        }

        @media only screen and (max-width: 699.9px) {
            ::-webkit-scrollbar {
                width: 4px;
                border-radius: 12px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 12px;
            }
            body {
                font-size: 16px;
            }

            .big_logo {
                width: 100%;
                height: auto;
            }

            .auth {
                display: flex;
            }

            .auth>div:first-child {
                width: 100%;
                margin: auto;
                border-radius: 5px;
            }

            .auth>div:last-child {
                width: 100%;
                margin: auto;
                border-radius: 5px;
                position: fixed;
                height: 100%;
                top:0px;
                left: 0px;
                background: rgba(0,0,0,.3);
                z-index: 99999;
            }

            .auth_holder {
                width: 100%;
                height: 100%;
                margin: auto;
                padding: 18px 16px;
                overflow-y: scroll;
            }
        
            .m {
                display: inline-block;
            }

        }

            
            

    `

    const modal = `
        .mymodal {
            position: fixed; 
            z-index: 1; 
            padding: 12px;
            padding-top: 20px; 
            left: 0;
            top: 44px;
            width: 100%; 
            height: 100%; 
            overflow: auto;

        }
        
        /* Modal Content */
        .mymodal .modal-content {
            position: relative;
            margin: auto;
            padding: 0;
            max-width: 699.9px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            -webkit-animation-name: animatetop;
            -webkit-animation-duration: 0.4s;
            animation-name: animatetop;
            animation-duration: 0.4s
            border-radius: 17px;
        }
        
        /* Add Animation */
        @-webkit-keyframes animatetop {
            from {top:-300px; opacity:0} 
            to {top:0; opacity:1}
        }
        
        @keyframes animatetop {
            from {top:-300px; opacity:0}
            to {top:0; opacity:1}
        }
        
        
        .mymodal .modal-header {
            padding: 5px 16px;
            font-weight: 600;
        }
        
        .mymodal .modal-body {
            padding: 2px 16px;
            overflow-y: scroll;
        }

        .fixed_height {
            height: 450px;
        }
        
        .mymodal .modal-footer {
            padding: 8px 16px;
        }
    `

    const serverLoading = `
        .loader {
            border: 7px solid #f3f3f3;
            border-top: 7px solid #12529f;
            border-bottom: 7px solid #12529f;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            margin: 0 auto;
            margin-top: 10%;
            animation: spin .5s linear infinite;

        }

        .loader_holder {
        position: fixed;
        background-color: rgba(0,0,0,.4);
        z-index: 999999;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        }

        .loadingTextHolder {
            background: #fff;
            width: 320px;
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 4px;
            height: 30px;
            color: #12529f;
            overflow: hidden;
            text-align: center;
            margin: 10px auto;
            position: relative;
        }

        .loadingTextHolder > div {
            position: absolute;
            top: 60px;
            text-align: center;
            width: 100%;

        }

        .loadingText {
            animation: goup 15.9s linear forwards;
        }        

        .loadingText2 {
            animation: goup2 20.9s linear 13s 1 normal forwards;
        }

        .loadingText3 {
            animation: goup3 40.9s linear 29s 1 normal forwards;
        }

        .loadingText4 {
            animation: goup4 55.9s linear 63s 1 normal forwards;
        }

        .loadingText5 {
            animation: goup5 12s linear 113s 1 normal forwards;
        }

        @keyframes goup {
            0% {
                top: 2px;
            }
            90% {
                top: 2px
            }
            100% {
                top: -100px;
            }
        }

        @keyframes goup2 {
            0% {
                top: 40px;
            }
            10% {
                top: 2px;
            }
            90% {
                top: 2px;
            }
            100% {
                top: -100px;
            }
        }

        @keyframes goup3 {
            0% {
                top: 40px;
            }
            10% {
                top: 2px;
            }
            90% {
                top: 2px;
            }
            100% {
                top: -100px;
            }
        }

        @keyframes goup4 {
            0% {
                top: 40px;
            }
            10% {
                top: 2px;
            }
            90% {
                top: 2px;
            }
            100% {
                top: -100px;
            }
        }

        @keyframes goup5 {
            0% {
                top: 40px;
            }
            10% {
                top: 2px;
            }
            90% {
                top: 2px;
            }
            100% {
                top: 2px;
            }
        }


        @keyframes spin {
            0% {
            transform: rotate(0deg);
            }
            

            100% {
            transform: rotate(360deg);
            }
        } 
    `

    const color = `
        html {
            background: url(${props.file}image/bg.jpg) no-repeat center center fixed; 
        }

        .bg_img {
            background-color: #fff;
            background: url(${props.file}image/bg.jpg) no-repeat center center fixed; 
        }

        .default_bg {
            background: #fff !important;
            color: #000 !important;
        }

        .text_color {
            color: #000 !important;
        }
        .primary_text {
            color: #12529f !important;
            background: #fff;
        }
        .primary_bg {
            color: #fff !important;
            background: #12529f;
        }
        .white_bg {
            background: #fff !important;
        }
        .bg_container {
            background: #fcfcfc;
        }

        .basic_container {
            background: #fff;
        }
        .basic_container>h2 {
            color: #12529f;
        }

        ::-webkit-scrollbar {
            background-color: rgba(40, 167, 69, .3);
        }

        ::-webkit-scrollbar-thumb {
            background-color: #12529f;
        }

        ::-webkit-scrollbar-thumb:hover {
            opacity: .3;
        }

        ::-webkit-scrollbar-button {
            background-color: #12529f;
            background-size: 10px 10px;
            background-repeat: no-repeat;
            background-position: center center;
            height: 18px;
            width: 1em;
            -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
        }

        ::-webkit-scrollbar-button:horizontal:increment {
            background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
        }

        ::-webkit-scrollbar-button:end:increment {
            background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
        }
        

        ::-webkit-scrollbar-button:start:decrement {
            background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==)
        }

        .form-control {
            border-color: #12529f !important;
        }
        .form-control:focus {
            border-color: #12529f !important;
        }

        .form-group .toggle_btn {
            background: transparent;
        }

        

        .total_quest {
            color: #12529f;
            background-color: #fff;
            border-top: 2px solid #12529f;
            border-left: 2px solid #12529f;
            border-right: 2px solid #12529f;

        }

        .question_holder {
            border-top: 2px solid #fff;
        }

        
        .curQuest {
            background: #12529f;
            color: #fff !important;
        }
        .ansQuest {
            background: #12529f;
            color: #000;
        }

        .question_btngrp>button {
            background: #12529f;
            color: #fff;
        }

        .question_sec {
            background-color: #fff;
            color: #12529f;
            border-bottom: 2px solid #12529f;
            border-left: 2px solid #12529f;
            border-right: 2px solid #12529f;
        }

        .sel_ans_option {
            background: #fff;
            color: #12529f;            
            border: 2px solid #12529f;
        }

        .sel_ans_option>span {
            color: #fff;
            background: #fff;            
            border: 4px solid #e2e2e2;
        }

        .answerOptions {
            color: #fff;
            background: #12529f;            
            border: 2px solid #12529f;
        }

        .answerOptions>span {
            color: #fff;
            background: #12529f;            
            border: 4px solid #e2e2e2;
        }

        input[type='checkbox']+label {
            background-color: #f2f2f2;
            color: #000;
        }

        
        input[type='checkbox']:checked+label {
            background-color: #12529f;
            color: #fff;
        }

        .cbt_holder >*{
            background: #fff;
        }


    `

    const dcolor = `
        html {
            background: url(${props.file}image/dbg.jpg) no-repeat center center fixed; 
        }

        .bg_img {
            background-color: #000;
            background: url(${props.file}image/dbg.jpg) no-repeat center center fixed; 
        }
    `

    return (
        <style>
            {theme ? color : dcolor}
            {non_color}
            {modal}
            {serverLoading}
        </style>
    )
} 

export default AppStyle
import Chat from "./subpages/Chat"
import Class from "./subpages/Class"
import Dashboard from "./subpages/Dashboard"

const View = (props) => {
    const setView = (link) => {
        if (link==="class") {
            return <Class send_class_msg={props.send_class_msg} api={props.api} file={props.file} paymentPending={props.paymentPending} winWidth={props.winWidth} />
        }
        if (link==="chat") {
            return <Chat api={props.api} file={props.file} paymentPending={props.paymentPending} winWidth={props.winWidth} />
        }
        if (link==="dashboard") {
            return <Dashboard api={props.api} file={props.file} paymentPending={props.paymentPending} winWidth={props.winWidth} />
        }
        return "Not Found"
    }
    return (
        <div className="view">  
            {setView(props.link)}
        </div>
    )
}

export default View
import { Chat, ChatFill, Mortarboard, MortarboardFill, Person, PersonFill } from "react-bootstrap-icons"
import Button from "../Public/Button"

const Navigation = (props) => {
    return (
        <div className="nav_">
            <div className="navigation default_bg">
                <Button onclick={() => props.setLink("class")} name={<>{props.link==="class" ? <MortarboardFill className="nav_btn_ico" />:<Mortarboard className="nav_btn_ico" />}</>}  title="Class" className="nav_btn" />
                <Button onclick={() => props.setLink("chat")} name={props.link==="chat" ? <ChatFill className="nav_btn_ico" />:<Chat className="nav_btn_ico" />} title="Chat" className="nav_btn" />
                <Button onclick={() => props.setLink("dashboard")} name={props.link==="dashboard" ? <PersonFill className="nav_btn_ico" />:<Person className="nav_btn_ico" />} title="Dashboard" className="nav_btn" />
            </div>
            <div className="mv nav_title" style={{textAlign: "center"}}>
                <span onClick={() => props.setLink("class")} style={{color: props.link==="class" ? "#12529f":"#ccc", textAlign: "center"}} title="Class" className="nav_btn_title" >Class Room</span>
                <span onClick={() => props.setLink("chat")} style={{color: props.link==="chat" ? "#12529f":"#ccc", textAlign: "center"}} title="Chat" className="nav_btn_title">Chat Room</span>
                <span onClick={() => props.setLink("dashboard")} style={{color: props.link==="dashboard" ? "#12529f":"#ccc", textAlign: "center"}} title="Dashboard" className="nav_btn_title">Dashboard</span>
            </div>
        </div>
    )
}

export default Navigation
import React, { useState } from 'react';
import axios from 'axios';
import Button from '../pages/components/Public/Button';
import Cookies from "js-cookie"
import CountdownTimer from '../pages/components/Public/CountdownTimer';
import { ChevronLeft } from 'react-bootstrap-icons';
import ServerLoading from '../pages/components/Public/ServerLoading';
import { Link } from 'react-router-dom';

const VerifyEmail = (props) => {
  const [email, setEmail] = useState(props.email)
  const [code, setCode] = useState("")
  const [ver, setVer] = useState(false)
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [resend, setResend] = useState(false)

  const student_id = Cookies.get("mdjdiowd")|| ""

  const extFunc = () => {
    setResend(true)
  }

  const verify = async () => {
    setDisabled(true)
    try {
      const response = await axios.post(props.api+'api/confirm.php', {
        email: email,
        code: code,
      });

      if (response.data.success) {
        setDisabled(false)
        setError(false)
        setMessage(response.data.success)
        props.setEmailVerified(true)
      } else {
        setDisabled(false)
        setError(true)
        setMessage(response.data.message)
      }
    } catch (error) {
        setDisabled(false)
        alert('An error occurred. Please try again.');
    }
};

  const sendVerification = async () => {
    setDisabled(true)
    try {
      const response = await axios.post(props.api+'api/verification.php', {
      email,
      student_id
      });
      if (!(response.data.success)) {
        setDisabled(false)
        setError(true)
        setMessage(response.data.message)
      } else {
        setDisabled(false)
        setVer(true)
        setError(false)
        setMessage(`A confirmation code has been sent to ${email} from class.welearn.`)
        setResend(false)
      }
    } catch (error) {
      setDisabled(false)
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <ServerLoading display={disabled} />
      <div className="tool_con">
        <div className="tool_holder default_bg">
          <div className="tool_holder_header">
            <h3 style={{fontWeight: "bolder", textTransform: "uppercase", textAlign: "center"}}> Verify Email </h3>
          </div>
          <div className="tool_holder_body">
            { message? <Button styles={{display: "block", margin: "4px auto", fontSize: "13px"}} name={message} error={error} success={!error} disabled /> : ""}
            {
              !ver ?
              <>

                <div className="form-group" style={{margin: "6px 0px"}}>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    
                  />
                </div>
                <Button name="Send Code" onclick={() => sendVerification()}  />
              </> : 
              <>
                <div className="form-group" style={{margin: "6px 0px"}}>
                  <label htmlFor="code">Verification Code:</label>
                  <input
                    type="number"
                    id="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="form-control"
                    
                  />
                </div>
                <div style={{
                  fontSize: "14px",
                  display: "flex",
                  flexDirection: "row"
                }}>
                  <Button name={<><ChevronLeft /> Back</>} styles={{display: "block", flex: "1", marginRight: "3px"}} onclick={() => {
                    setVer(false)
                    setMessage("")
                    setError(false)
                    setResend(false)
                    setCode("")
                  }}  />

                  <Button name="Verify" styles={{textAlign: "center", display: "block", flex: "2", marginRight: "3px"}} success onclick={verify}  />

                  <Button name={!resend ? <>Resend <CountdownTimer phpTimestamp={Number(Number(Date.now())/1000)+45} sec extFunc={() => extFunc()} /></> : "Resend Code"} disabled={!resend} info styles={{textAlign: "center", display: "block", flex: "2"}} onclick={() => {
                    sendVerification()
                    setMessage("")
                    setError(false)
                    setResend(false)
                    setCode("")
                  }} />
                </div>
              </>
            }
          </div>
          <div className="tool_holder_footer">
            Not your account? <Link to="/logout" style={{textDecoration: "none"}}>Logout</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;

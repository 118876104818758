import Button from "../pages/components/Public/Button"
import TimeTable from "../../src/assets/img/timetable.jpg"
import InputMethod from "../pages/components/Public/InputMethod"
import axios from "axios";
import { useState } from "react";
import Policy from "./Policy";
import Cookies from "js-cookie"
import { Link } from "react-router-dom";

const VerifyPayment = (props) => {
    const [selectedImages, setSelectedImages] = useState(null);
    const [display, setDisplay] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [upload_rate, setUpload_rate] = useState(0)

    const student_id = Cookies.get("mdjdiowd")|| ""

    const uploadReciept = async (e) => {
        e.preventDefault()
        if (!e.target.confirm_rules.checked) {
            return alert("You have to check the box before you continue")   
        }
        if (!selectedImages) {
            return alert("Please choose receipt file")
        }
        if (!e.target.duration.value) {
            return alert("Select Price")
        }
        setDisabled(true)

        const config = {
            onUploadProgress: function (progressEvent) {
                const { loaded, total } = progressEvent
                setUpload_rate(0)

                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setUpload_rate(percent);
                } else {
                    setUpload_rate(100);
                }
            }
        }
        
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/upload_receipt")
        formData.append("student_id",student_id)
        formData.append("receipt", selectedImages)
        formData.append("duration", e.target.duration.value)

        try {
            const response = await axios.post(props.api,formData,config);
            try {
                if (!response.data.error) {
                    props.setPaymentPending(true)
                    props.setPaymentVer(true)
                    window.location.replace("/")
                } else {
                    alert(response.data.message)
                    setDisabled(false)
                }
                setUpload_rate(100)
            } catch {
            }                
        } catch (err) {
            alert("Network error")
        }

    }

    const imageUpload = (event) => {
        const files = event.target.files[0];
        if (files.size > (3 * 1024 * 1024)) {
            alert(`File size exceeds the limit of ${3} MB: ${files.name}`);
            event.target.value=""
            return
        }

        const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp'];

        if (!allowedMimeTypes.includes(files.type)) {
            alert(`File Must be images`);
            event.target.value=""
            return
        }
        setSelectedImages(files);
        event.target.value = "";
    };

    const styles = {
        imageGrid: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          gap: '10px',
          marginTop: '8px',
          width: "200px",
          margin: "4px auto"
        },
        imageContainer: {
          position: 'relative',
          height: '100px',
          width: '100px',
          margin: "auto",
          overflow: 'hidden',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        },
        image: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }
    };

    return (
        <>
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <h3 style={{fontWeight: "bolder", textTransform: "uppercase", textAlign: "center"}}> Make Payment </h3>
                    </div>
                    <form onSubmit={uploadReciept}>
                    <div className="tool_holder_body">
                        <table style={{textAlign: "center"}} className="table table-striped table-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} style={{textAlign: "center", fontWeight: "bolder"}}>
                                        PAYMENT SUBSCRIPTION PLANS
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        S/N
                                    </th>
                                    <th>
                                        DURATION
                                    </th>
                                    <th>
                                        AMOUNT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        1.
                                    </td>
                                    <td>
                                        MONTHLY
                                    </td>
                                    <td>
                                        &#8358; 5,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2.
                                    </td>
                                    <td>
                                        2 MONTHS
                                    </td>
                                    <td>
                                        &#8358; 9,500
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3.
                                    </td>
                                    <td>
                                        3 MONTHS
                                    </td>
                                    <td>
                                        &#8358; 13,500
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4.
                                    </td>
                                    <td>
                                        4 MONTHS
                                    </td>
                                    <td>
                                        &#8358; 17,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5.
                                    </td>
                                    <td>
                                        6 MONTHS
                                    </td>
                                    <td>
                                        &#8358; 24,000
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={3} style={{textAlign: "center", fontWeight: "bolder"}}>
                                        ACCOUNT DETAILS
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        Bank Name
                                    </th>
                                    <th>
                                        Account Number
                                    </th>
                                    <th>
                                        Account Name
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        WEMA BANK
                                    </td>
                                    <td>
                                        0125918116
                                    </td>
                                    <td>
                                        SUCCESSMINDS EDUCATIONAL SERVICES WE LEARN CLASSROOM
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    <div style={{textAlign: "center", margin: "14px 0px"}}>
                        <select name="duration" className="form-control" required>
                            <option value="">
                                CHOOSE PAYMENT PLAN
                            </option>
                            <option value="30">
                                1 MONTH (30 days)	&#8358;5,000
                            </option>
                            <option value="60">
                                2 MONTHS (60 days) &#8358;9,500
                            </option>
                            <option value="90">
                                3 MONTHS (90 days)	&#8358;13,500
                            </option>
                            <option value="120">
                                4 MONTHS (120 days)	&#8358;17,000
                            </option>
                            <option value="180">
                                6 MONTHS (180 days)	&#8358;24,000
                            </option>
                        </select>
                        <label htmlFor="_images" style={{padding: "8px 14px", background: "#12529f", color: "#fff", borderRadius:"8px", cursor: "pointer", margin: "5px ", fontSize: "14px"}}>
                            Choose Payment Reciept (image only)
                        </label>
                        <input 
                            type="file" 
                            onChange={imageUpload} 
                            accept="image/*"
                            id="_images"
                            style={{display: "none"}}
                        />
                        {
                            selectedImages ?
                            <div style={styles.imageGrid}>
                                <div style={styles.imageContainer}>
                                    <img
                                        src={URL.createObjectURL(selectedImages)}
                                        alt={`upload-receipt`}
                                        style={styles.image}
                                    />
                                </div>
                            </div>
                            :
                            ""
                        }
                    </div>
                        <div className="sm_ele_grid" style={{margin: "4px auto"}}>
                            <Button name="View Class Policy" styles={{textAlign: "center"}} onclick={() => setDisplay(true)} />
                            
                            <a href={TimeTable} style={{
                                margin: "4px auto",
                                textDecoration: "none",
                                border: "1px solid #12529f", 
                                display: "block",
                                width: "100%",
                                textAlign: "center",
                                padding: "4px"
                            }} target="_blank" rel="noreferrer" download="timetable.jpg"> Download Time Table </a>
                        </div>
                        <InputMethod name="confirm_rules" label="I have read the class policy and I agree" checklist style={{border: "none"}} required/>
                    </div>
                    <div className="tool_holder_footer">
                        Not your account? <Link to="/logout" style={{textDecoration: "none"}}>Logout</Link>
                        {
                            disabled?
                            <div style={{width: "100%", height: "fit-content", background: "rgba(18, 82, 159, .3)", position: "relative", borderRadius: "4px", overflow: "hidden", }} onClick={() => setDisabled(false)}>
                                <div style={{minWidth: "25px", width: upload_rate+"%", height: "30px", position: "relative",background: "rgba(18, 82, 159, 1)", transition: "all .3s"}}>
                                    <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", fontSize: "12px", transition: "all .3s", color: "#fff"}}>
                                        {upload_rate}%
                                    </span>
                                </div>
                            </div>
                            :
                            <Button name="Submit Payment Reciept" form/>
                        }
                        
                    </div>
                    </form>
                </div>
            </div>
            {display ? <Policy display={display} setDisplay={setDisplay} />: ""}
        </>
    )
}

export default VerifyPayment
import { Link } from "react-router-dom";
import Cookies from "js-cookie"
import { useEffect, useState } from "react";
import CountdownTimer from "../../Public/CountdownTimer";
import Button from "../../Public/Button";


const Dashboard = (props) => {
    const student_id = Cookies.get("mdjdiowd")
    const [userDetails, setUserDetails] = useState(JSON.parse(Cookies.get("userDetails")))
    return (
        <div style={{padding: "10px"}} className="profile_holder">
            <div className="main_details_holder">
                <div className="my_pp">
                    <img src={props.api+userDetails.passport} alt="" />
                </div>
                <div style={{textAlign: "center", fontWeight: "bolder"}}>
                    {userDetails.firstname.toUpperCase()} {userDetails.middlename.toUpperCase()} {userDetails.surname.toUpperCase()}
                </div>
                <div style={{textAlign: "center"}}>
                    {userDetails.email}
                </div>
                <Link to="logout" style={{
                    background: "#dc3545",
                    color: "#fff",
                    textDecoration: "none",
                    padding: "8px 14px",
                    borderRadius: "4px",
                    display: "block",
                    margin: "3px auto",
                    width: "fit-content"
                }}>Logout</Link>
            </div>
            <div className="other_details_holder">
                <div>
                    <table className="table table-striped" style={{textTransform: "capitalize"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{fontSize: "22px", background: "inherit"}}>
                                    Personal Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Gender:
                                </td>
                                <td>
                                    {userDetails.gender.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date of Birth:
                                </td>
                                <td>
                                    {userDetails.dob.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WhatsApp Number:
                                </td>
                                <td>
                                    {userDetails.whatsapp.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Number:
                                </td>
                                <td>
                                    {userDetails.phone.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Course:
                                </td>
                                <td>
                                    {userDetails.course.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Session:
                                </td>
                                <td>
                                    {userDetails.class_session.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Type:
                                </td>
                                <td>
                                    {userDetails.class_type.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Payment Status:
                                </td>
                                <td>
                                    {
                                        userDetails.payment_pending==="1" ?
                                        "Pending" :
                                        userDetails.payment_exp ?
                                        "Not in a Plan" :
                                        <>Paid - Expires in <CountdownTimer phpTimestamp={userDetails.payment_dur} /> 
                                        <br />
                                        {
                                            userDetails.payment_pending==="1" || <Link to="repayment"> <Button name={"Add Subscription"} /> </Link>
                                        } </>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
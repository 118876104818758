import { useRef, useState } from "react"
import Button from "../Public/Button"
import InputMethod from "../Public/InputMethod"
import axios from 'axios';
import Cookies from "js-cookie"
import ServerLoading from "../Public/ServerLoading";

const Signup = (props) => {
    const [step, setStep] = useState(1)
    const [classType, setClassType] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [uploadProgress, setUploadPercentage] = useState("")
    const formContainer = useRef(null)

    const student_id = Cookies.get("mdjdiowd")|| ""

    const submitHandler = async (e) => {
        e.preventDefault()
        setDisabled(true)

        const firstname = String(e.target.firstname.value).trim()
        const middlename = String(e.target.middlename.value).trim()
        const surname = String(e.target.surname.value).trim()
        const whatsapp = String(e.target.whatsapp.value).trim()
        const phone = String(e.target.phone.value).trim()
        const email = String(e.target.email.value).trim()
        const dob = String(e.target.dob.value).trim()  
        const gender = e.target.gender.value  
        const password = e.target.password.value
        const repassword = e.target.repassword.value
        const course = e.target.course.value
        const parent = e.target.parent.value
        const class_type = e.target.class_type.value

        let class_session = ""
        if (class_type==="SSCE/JAMB") {
            class_session = e.target.s_jamb_time.value
        } else {
            class_session = e.target.s_pume_time.value
        }
        
        try {
            const filename = e.target.passport.files[0].name
            if (filename==="") {
                alert("No file found")
                setDisabled(false)
                return
            }
            if (e.target.passport.files[0].size>5000000) {
                alert("passport size should not be more than 5MB")
                setDisabled(false)
                return
            }
        } catch (error) {
            alert("Select a Photo")
            setDisabled(false)
            return false
        }
 


        if (!firstname||!surname||!whatsapp||!email||!dob||!password||!repassword||!class_session||!gender||!course||!parent) {
            alert("Fill all required field (*)")
            setDisabled(false)
            return
        }


        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/signup")
        formData.append("student_id",student_id)
        formData.append("firstname",firstname)
        formData.append("middlename",middlename)
        formData.append("surname",surname)
        formData.append("whatsapp",whatsapp)
        formData.append("phone",phone)
        formData.append("email",email)
        formData.append("dob",dob)
        formData.append("gender",gender)
        formData.append("password",password)
        formData.append("repassword",repassword)
        
        formData.append("class_type",class_type)
        formData.append("class_session",class_session)
        
        formData.append("course",course)
        formData.append("parent",parent)
        formData.append("passport",e.target.passport.files[0])


        const config = {
            onUploadProgress: function (progressEvent) {
                const { loaded, total } = progressEvent
                setUploadPercentage(0)

                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setUploadPercentage(percent);
                } else {
                    setUploadPercentage(100);
                }
            }
        }

        try {
            const response = await axios.post(props.api, formData, config);
            if (response.data.error) {
                alert(response.data.message)
            } else {
                Cookies.set("mdjdiowd",response.data.student_id,{path: '/', expires: new Date(Date.now()+21212592000)})
                window.location.reload()
            }
            console.log(response.data)
            setDisabled(false)
        } catch(err) {
            console.log(err)
            setDisabled(false)
        }
    }

    const toTop = () => {
        if (formContainer.current) {
            formContainer.current.scrollIntoView({ behavior: 'smooth' })
        } 
    }

    return (
        <div>
            <ServerLoading display={disabled} progress perc={uploadProgress} />
            <div style={{ marginTop: "0px", marginBottom: "30px"}} >
                <Button name="Sign Up" styles={{marginRight:"10px"}} info />
                <Button name="Sign In" onclick={() => {
                    props.setSignin()
                }} />
            </div>
            <form style={{position: "relative", paddingTop: "40px"}} method="post" encType="multipart/form-data" onSubmit={submitHandler}>
                <span style={{padding: "2px 12px", background: "#12529f", color: "#fff", position: "absolute", right:"12px", top: "12px", borderRadius: "20px"}} ref={formContainer} id="formContainer">
                    {step}/3
                </span>
                <div style={{display:step===1?"block":"none"}}>
                    <InputMethod label={<>First Name: <span style={{color: "red"}}>*</span></>} name="firstname" />
                    <InputMethod label="Middle Name:" name="middlename" />
                    <InputMethod label={<>Surname: <span style={{color: "red"}}>*</span></>} name="surname" />
                    <InputMethod label={<>WhatsApp Number: <span style={{color: "red"}}>*</span></>} name="whatsapp" phone />
                    <InputMethod label={<>Other Number: <span style={{color: "red"}}>*</span></>} name="phone" phone />
                    <InputMethod label={<>Email: <span style={{color: "red"}}>*</span></>} name="email" />
                    <InputMethod label={<>Date of Birth: <span style={{color: "red"}}>*</span></>} name="dob" date/>
                    <b style={{display: "block",marginTop: "10px"}}>
                        Gender: 
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="gender" value="male" label="Male" radio checked/>
                        <InputMethod name="gender" value="female" label="Female" radio />
                    </div>
                    <InputMethod label={<>Password: <span style={{color: "red"}}>*</span></>} password name="password" />
                    <InputMethod label={<>Repeat Password: <span style={{color: "red"}}>*</span></>} password name="repassword" />
                </div>
                <div style={{display:step===2?"block":"none"}}>
                    <b>
                        Class Type:
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="class_type" value="SSCE/JAMB" label="SSCE/JAMB" radio checked onChange={() => {setClassType(true)}} />
                        <InputMethod name="class_type" value="Post UTME" label=" Post UTME" radio onChange={() => {setClassType(false)}} />
                        <InputMethod name="class_type" label={<span> UNIBEN 100L <i>(coming soon)</i></span>} disabled radio />
                    </div>
                    <b>
                        Select Session:
                    </b>
                    <div className="sm_ele_grid" style={{display: classType?"grid":"none"}}>
                        <InputMethod name="s_jamb_time" value="Morning Session" label="Morning Session (8am - 11am)" radio checked />
                        <InputMethod name="s_jamb_time" value="Afternoon Session" label="Afternoon Session (12pm-3pm)" radio />
                        <InputMethod name="s_jamb_time" value="Evening Session" label="Evening Session (4pm-7pm)" radio />
                        <InputMethod name="s_jamb_time" value="Late Evening Session" label="Late Evening Session (8pm-11pm)" radio />
                        <InputMethod name="s_jamb_time" value="Mid Night Session" label="Mid Night Session (12am-3am)" radio />        
                    </div>
                    <div className="sm_ele_grid" style={{display: classType?"none":"grid"}}> 
                        <InputMethod name="s_pume_time" value="Morning Session" label="Morning Session (8am - 11am)" radio checked />
                        <InputMethod name="s_pume_time" value="Evening Session" label="Evening Session (8pm-11pm)" radio />            
                    </div>
                    <InputMethod label={<>Parents/Guardian Number: <span style={{color: "red"}}>*</span></>} phone name="parent"/>
                </div>
                <div style={{display:step===3?"block":"none"}}>
                    
                <b>Select Course:</b>
                    <InputMethod name="course" label={
                        <>
                            Medical Sciences & Life Sciences 
                            <br/>
                            (English, Biology, Physics and Chemistry)
                        </>
                    } value="Medical Sciences & Life Sciences" radio checked/>
                    <InputMethod name="course" label={
                        <>
                            Arts & Law
                            <br/>
                            (English, Government, Literature & CRS)
                        </>
                    } value="Arts & Law" radio/>
                    <InputMethod name="course" label={
                        <>
                            Engineering & Physical Sciences 
                            <br/>
                            (English, Mathematics, Physics and Chemistry)
                        </>
                    } value="Engineering & Physical Sciences" radio/>
                    <InputMethod name="course" label={
                        <>
                            Management Sciences 
                            <br/>
                            (English, Mathematics, Government and Economics)
                        </>
                    } value="Management Sciences" radio/>
                    <InputMethod name="course" label={
                        <>
                            Social Sciences
                            <br/>
                            (English, Government, Economics and CRS)
                        </>
                    } value="Social Sciences" radio/>

                    <div className="form-group">
                        <label htmlFor="passport" className="form-label" style={{fontWeight: "bold"}}>Passport: <span style={{color: "red"}}>*</span> <i style={{fontWeight: "lighter", fontSize: "14px"}}>Passport size should be equal to or less than 5MB</i> </label>
                        <input type="file" id="passport" name="passport" className="form-control" accept="image/*" />
                    </div>
                </div>

                <div style={{position: "relative", marginTop: "12px"}}>
                    <Button name="Prev" styles={{display: step<= 1 ? "none" : "block", position: "absolute", left: "0px", top: "0px"}} onclick={() => {
                        setStep(step-1)
                        toTop()                 
                    }} />
                    
                    <Button name="Next" onclick={() => {
                        setStep(step+1)                        
                        toTop()
                    }} styles={{position: "absolute", right: "0px", top: "0px", display: step<3?"inline-block":"none"}}/>
                    <Button name="Sign Up" form={true} styles={{position: "absolute", right: "0px", top: "0px", display: step<3?"none":"inline-block"}} />  
                </div>
                

                <br/>
                <br/>
                <br/>
            </form>
        </div>
    )
}
export default Signup
import { X } from "react-bootstrap-icons"
import Button from "../pages/components/Public/Button"

const Policy = (props) => {
    return (
        <div> 
        <div style={{
            display: props.display?"block": "none",
            position: "fixed",
            zIndex: "9999999",
            width: "100%"
        }} className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <h3 style={{fontWeight: "bolder", textTransform: "uppercase", textAlign: "center"}}> CLASS POLICY </h3>
                        <Button className="canc_btn" onclick={() => props.setDisplay(false)} name={<X />} error />
                    </div>
                    <div className="tool_holder_body" style={{textAlign: "justify"}}>
                        <ol className="class_policy_list">
                            <li>
                                Upload payment receipt after each payments in the app. Your payment must be confirmed by the admin before you will be automatically allowed or retained in class. You could subscribe for a month, 2 months or one time payment till exam.
                            </li>

                            <li>
                                80% minimum Class attendance is compulsory within a month to allow you continue learning with us. Must be present in class at the exact time, as if it were a physical engagement. Your attendance and time of sign in to class Is automatically recorded by the app.
                            </li>

                            <li>
                                You must copy and make your notes, take post module/topic TMA/CBT and score at least 50% before proceeding to next lesson. Lest the system will log you out. Hence you must take every lesson seriously.
                            </li>

                            <li>
                                High level of discipline is demanded. No use of obscene, vulgar language, no use of stickers on the We Learn Whatsap Community.
                            </li>

                            <li>
                                All Tutors Marked  Assignments, TMA (Theory and CBT) must be completed within the programmed deadline, or you will be logged out.
                            </li>

                            <li>
                                You must tick your attendance after each class on your attendance dashboard on the We Learn App. Or react to the lesson outline post by tutor to show your attendance if the class is held on WhatsApp.
                            </li>

                            <li>
                                When you refer a contact to sign up, chat the financial admin with proof of the person's payment for your referal commission.
                            </li>

                            <li>
                                Private chat any of the support admin on the site, fliers or whatsap community if you have any personal challenges in payments, sign up, learning, course material needs, or with a tutor, e.t.c.
                            </li>

                            <li>
                                Ensure you download your class timetable after sign up on the site. As classes are programmed to hold as scheduled on your timetable.
                            </li>

                            <li>
                                You must be an active learning participant in subjects classrooms. You must be bold and willing to  ask questions, make comments on the tutors class modules sent to you. On the days for students' collaborations, you should be assertive to initiate or join students discussion groups and collaborate with others going for similar fields or courses. You learn best when you are active, and collaborate according to learning psychologists.
                            </li>
                        </ol>

                        <p>
                            <b>N.B:</b>  Our professional team understands perfectly the nature of all your exams, be it Waec, Utme, Putme for all Universities in Nigeria; our tutors are versed in the dynamics of the nature of the exams. Open up, enjoy the platform to the max. as "We Learn"
                        </p>

                        <i>Learning App built with you in mind!</i>
                    </div>
                    <div className="tool_holder_footer">
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Policy
import { useState } from "react";

const Chat = (props) => {
    const [s404] = useState(true)
    return (
        <>
            {
                !s404 ?
                <div style={{background: "#fff"}}>
                    Student Chat room {props.api}
                </div>:
                <div style={{
                    textAlign: "center",
                    background: "#fff",
                    height: "100%"
                }}>
                    <p style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "12px", fontStyle: "italic", color: "#888"}}>
                        Coming soon...
                    </p>
                </div>
            }
        </>
    )
}

export default Chat;
import { useState } from "react"
import Navigation from "./components/Home/Navigation"
import View from "./components/Home/View"
import "./components/Home/Home.css"

const Home = (props) => {
    const [link, setLink] = useState("class")

    return (
        <div className="home_stu">
            <View link={link} api={props.api} paymentPending={props.paymentPending} file={props.file} winWidth={props.winWidth} send_class_msg={props.send_class_msg} />
            <Navigation setLink={setLink} link={link}  winWidth={props.winWidth} />
        </div>
    )
}
export default Home
import { Routes, Route, useNavigate, Link } from 'react-router-dom'
import AppStyle from './AppStyle';
import { useCallback, useEffect, useState } from 'react';
import ServerLoading from '../pages/components/Public/ServerLoading';
import axios from 'axios';
import Cookies from "js-cookie"

import Home from '../pages/Home';
import Auth from '../pages/Auth';
import Logout from '../pages/components/Auth/Logout';
import VerifyEmail from './VerifyEmail';
import VerifyPayment from './VerifyPayment';
import Button from '../pages/components/Public/Button';

const Appview = (props) => {
    const [disabled, setDisabled] = useState(false)
    const [emailVerified, setEmailVerified] = useState(true)
    const [email, setEmail] = useState("")
    const [paymentPending, setPaymentPending] = useState(false)
    const [paymentVer, setPaymentVer] = useState(true)
    const [firstname, setFirstname] = useState("")
    const [surname, setSurname] = useState("")
    const [middlename, setMiddlename] = useState("")
    const [expDur, setExpDur] = useState("")
    const [renewSub, setRenewSub] = useState(true)

    const navigate = useNavigate()

    const student_id = Cookies.get("mdjdiowd")|| ""

    function convertToJSONArray(jsonString) {
        // Match all JSON objects using regex
        const jsonObjects = jsonString.match(/({.*?})/g);
        if (!jsonObjects) {
            return [];
        }
    
        // Parse each JSON object and add to array
        const jsonArray = jsonObjects.map(jsonObject => JSON.parse(jsonObject));
        return jsonArray;
    }

    // useEffect(() => {
    //     if (student_id) {
    //         socket.emit('setUserId', student_id);

    //         socket.on('class_msg_recieved', ({ message_id, class_id, topic, message, lesson_outlines, teaching_objectives, student_id, now }) => {
    //             // Update messages only if they belong to the current group being viewed
    //             // console.log(class_id, topic, message, lesson_outlines, teaching_objectives, student_id, now);
    //             if (student_id !== Cookies.get("mdjdiowd")) {
    //                 const storedMsg = sessionStorage.getItem("student_class_msg")
    //                 let msg = {
    //                     message_id: message_id,
    //                     class_id: class_id,
    //                     topic: topic,
    //                     content: message,
    //                     lesson_outlines: lesson_outlines,
    //                     teaching_objectives: teaching_objectives,
    //                     student_id: student_id,
    //                     date: now,
    //                     sent: 1,
    //                 } 
    //                 const jsonMsg = JSON.parse(storedMsg)
    //                 jsonMsg.push(msg)

    //                 // alert("new message")

    //                 sessionStorage.setItem('student_class_msg', JSON.stringify(jsonMsg));
    //             }  else {
    //                 let msg = {
    //                     message_id: message_id,
    //                     class_id: class_id,
    //                     topic: topic,
    //                     content: message,
    //                     lesson_outlines: lesson_outlines,
    //                     teaching_objectives: teaching_objectives,
    //                     student_id: student_id,
    //                     date: now,
    //                     sent: 1,
    //                 } 
    //                 const storedMsg = sessionStorage.getItem("student_class_msg")
    //                 const jsonMsgs = JSON.parse(storedMsg)
    //                 const updatedMsgs = jsonMsgs.filter(msg => msg.message_id !== message_id)
    //                 updatedMsgs.push(msg)
    //                 // const newJsonMsgs = jsonMsgs.map((jsonMsg) => {
    //                 //     if (jsonMsg.message_id===message_id) {
    //                 //         jsonMsg.sent=1
    //                 //         return jsonMsg
    //                 //     }
    //                 //     return jsonMsg
    //                 // })
    //                 sessionStorage.setItem('student_class_msg', JSON.stringify(updatedMsgs))
    //             }
    //         });

    //         socket.on('class_conv_recieved', ({ now, class_id }) => {

    //             const storedRm = sessionStorage.getItem("student_classes")
    //             const jsonRms = JSON.parse(storedRm)
    //             const newjsonRms = jsonRms.map((jsonRm) => {
    //                 if (jsonRm.class_id===class_id) {
    //                     jsonRm.update_time = now
    //                     return jsonRm
    //                 }
    //                 return jsonRm
    //             })

    //             sessionStorage.setItem('student_classes', JSON.stringify(newjsonRms))
    //         });

    //         return () => {
    //             console.log('Disconnecting from server');
    //             socket.disconnect();
    //         };
    //     }
    // },[student_id, socket])

    const fetchStudentDetails = useCallback( async () => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/exist")
        formData.append("student_id",student_id)
        
        try {
            const response = await axios.post(props.api,formData);
            if (!response.data.success) {
                console.log("Student not found:", response.message);
                Cookies.remove("mdjdiowd","",-1)
                Cookies.remove("userDetails","",-1)
                sessionStorage.removeItem('student_classes')
                navigate("/")
                window.location.reload()
                return
            } else {
                Cookies.set("userDetails",JSON.stringify(response.data),{path: '/', expires: new Date(Date.now()+21212592000)})
                setExpDur(response.data.payment_dur)
                setFirstname(response.data.firstname)
                setSurname(response.data.surname)
                setMiddlename(response.data.middlename)
                if (response.data.verify_email==="0") {
                    setEmail(response.data.email)
                    setEmailVerified(false)
                }

                if(response.data.payment_pending==="0") {
                    setPaymentPending(false)
                    checkPayment()
                } else {
                    setPaymentPending(true)
                    setPaymentVer(true)
                    setDisabled(false)
                }
            } 
        } catch {
            alert("You are offline")
        }
    }, [])
    
    const studentClassRoom = useCallback(async () => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/student_conv")
        formData.append("student_id",student_id)

        try {
            const response = await axios.post(props.api,formData);
            try {
                const jsonArr = response.data
                const sortArrAs = jsonArr.sort((a, b) => new Date(b.update_time) - new Date(a.update_time))
                sessionStorage.setItem('student_classes', JSON.stringify(sortArrAs));
                
            } catch (error) {
                alert("Site under Maintenance. Try again later")
                
            }                  
        } catch (err) {
            console.log("Network error")
        }
    },[props.api, student_id])

    const studentClassRoomMsg = useCallback(async () => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/student_msg")
        formData.append("student_id",student_id)
        
        try {
            const response = await axios.post(props.api,formData);
            const jsonObject = convertToJSONArray(response.data)
            try {
                sessionStorage.setItem('student_class_msg', JSON.stringify(jsonObject));
                
            } catch (error) {
                alert("Site under Maintenance. Try again later")
                
            }               
        } catch {
            console.log("network error")
        }
    }, [props.api, student_id])

    const emailVerification = useCallback(async () => {

        try {
            const response = await axios.post(props.api+"email_verification",{student_id});
            try {
                console.log(response.data)
                if (response.data.error) {
                    alert(response.data.message)
                    
                } else {
                    if (!response.data.success) {
                        if (String(response.data.message).toLowerCase()==="please verify email") {
                            setEmailVerified(true) 
                            setEmail(response.data.email)
                            return                           
                        }
                    }
                    
                }
            } catch (error) {
                alert("Site under Maintenance. Try again later ")
                
            }                  
        } catch (err) {
            console.log("network error")
        }
    }, [props.api, student_id])

    
    const checkPayment = useCallback(async () => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/check_payment")
        formData.append("student_id",student_id)
        
        try {
            const response = await axios.post(props.api,formData);
            if (response.data.error) {
                setPaymentVer(false) 
                setDisabled(false)
            } else {
                setPaymentVer(true)
                setDisabled(false)
            }  
        } catch {
            alert("Network error, couldn't verify Payment... Reload page")
            
        }
    }, [props.api, student_id])
    
    const pendingPayment = useCallback(async () => {
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/student/payment_pending")
        formData.append("student_id",student_id)
        
        try {
            const response = await axios.post(props.api,formData);
            if (response.data.error) {
                setPaymentVer(true)
                setPaymentPending(true) 
            } else {
                checkPayment()
                setPaymentPending(false)
            }   
        } catch {
            console.log("Network error")
        }
    }, [props.api, student_id])

    useEffect(() => {
        if (student_id) {
            setDisabled(true)
            fetchStudentDetails()
            const interval = setInterval(() => {
                fetchStudentDetails()
            }, 5000);
            return () => clearInterval(interval);
        }
        
    }, [student_id])

    useEffect(() => {
        if (student_id) {
            // emailVerification()
            // pendingPayment()
            if (paymentVer) {
                studentClassRoom()
                studentClassRoomMsg()
            }
        }
    }, [student_id, studentClassRoom, emailVerification, studentClassRoomMsg, pendingPayment]);
    
    const send_student_class_msg = (student_id, topic, message, teaching_objectives, lesson_outlines, class_id ) => {
        // const topicType = topic==="1" ? "topic" : topic==="0" ? "annoucement" : "exam"
        // const storedMsg = sessionStorage.getItem("student_class_msg")
        // const message_id = get_time()+"_"+topicType+"_"+class_id+"_"+student_id + "_" + generateRandomNumber()
        // const now = get_time()
        // let msg = {
        //     class_id: class_id,
        //     topic: topic,
        //     message_id: message_id,
        //     content: message,
        //     lesson_outlines: lesson_outlines,
        //     teaching_objectives: teaching_objectives,
        //     student_id: student_id,
        //     date: now,
        //     sent: 0,
        // } 
        // const jsonMsg = JSON.parse(storedMsg)
        // jsonMsg.push(msg)

        // sessionStorage.setItem('student_class_msg', JSON.stringify(jsonMsg));
        // socket.emit('send_student_class_msg', { student_id, topic, message, teaching_objectives, lesson_outlines, class_id, message_id });

        // const storedRm = sessionStorage.getItem("student_classes")
        // const jsonRms = JSON.parse(storedRm)
        // const newjsonRms = jsonRms.map((jsonRm) => {
        //     if (jsonRm.class_id===class_id) {
        //         jsonRm.update_time = now
        //         return jsonRm
        //     }
        //     return jsonRm
        // })

        // sessionStorage.setItem('student_classes', JSON.stringify(newjsonRms))
    };



    function get_time() {
        return Date.now();
    }

    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 90000) + 10000;
    };   

    const checkLogin = () => {
        if (student_id) {
            //server db fetch and store in local db in useEffect
           
            return(
                <>
                    <Route path="/" element={<Home send_student_class_msg={send_student_class_msg} paymentPending={paymentPending} api={props.api} file={props.file} winWidth={props.winWidth} />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/repayment" element={<VerifyPayment setPaymentVer={setPaymentVer} api={props.api} file={props.file} winWidth={props.winWidth} setPaymentPending={setPaymentPending} />} />
                    <Route path="*" element={<div>Page Unavailable</div>} />
                </>
            )
        } else {
            return (
                <>
                    <Route path="*" element={<Auth api={props.api} file={props.file} winWidth={props.winWidth} />} />
                </>
            )
        }
    }


    return (
        <>
            <ServerLoading display={disabled} />
            <AppStyle api={props.api} theme={true} file={props.file}/>
            {
                paymentPending || paymentVer && emailVerified && renewSub && expDur &&
                (Number(expDur)*1000)<Date.now()+(10*24*60*60*1000) && 
                <div style={{ position: "fixed", top:"12px", left: "50%", background: "#12529f", color: "#fff", zIndex: "99999999", padding: "6px 12px", width: "80%", transform: "translate(-50%, 0%)", borderRadius: "8px", fontSize: "14px", textAlign: "center" }}> 
                    <div>
                        Your Subscription will end in few days from now
                        <div style={{
                            textAlign: "right"
                        }}>
                            <Link to="repayment"> <Button name={"Add Subscription"} onclick={() => setRenewSub(false)} success/></Link>
                            <Button name="Hide" onclick={() => setRenewSub(false)} styles={{ marginLeft: "8px", marginTop: "8px", marginBottom: "4px" }} error />
                        </div>
                    </div>
                </div>
            }
            {
                paymentPending && emailVerified ?
                <div style={{ position: "fixed", top:"12px", left: "50%", background: "#12529f", color: "#fff", zIndex: "99999999", padding: "6px 12px", width: "80%", transform: "translate(-50%, 0%)", borderRadius: "8px", fontSize: "14px", textAlign: "center" }}> Payment Pending... WhatsApp Financial Admin; <a target='_blank' rel='noreferrer' style={{
                    color: "#fff",
                    textDecoration: "none", 
                    position: "relative"}} href={`https://wa.me/2348052396146?text=Hi%2C%20My%20name%20is%20${firstname.toUpperCase()}%20${middlename.toUpperCase()}%20${surname.toUpperCase()}%20from%20WeLearn%20online%20class.%20Please%20confirm%20my%20payment%20receipt.`}>Admin Wealth</a>, <a target='_blank' rel='noreferrer' style={{
                    color: "#fff",
                    textDecoration: "none", 
                    position: "relative"}} href={`https://wa.me/2349057517766?text=Hi%2C%20My%20name%20is%20${firstname.toUpperCase()}%20${middlename.toUpperCase()}%20${surname.toUpperCase()}%20from%20WeLearn%20online%20class.%20Please%20confirm%20my%20payment%20receipt.`}>Admin Beatrice</a>, <a target='_blank' rel='noreferrer' style={{
                        color: "#fff",
                        textDecoration: "none", 
                    position: "relative"}} href={`https://wa.me/2349053472040?text=Hi%2C%20My%20name%20is%20${firstname.toUpperCase()}%20${middlename.toUpperCase()}%20${surname.toUpperCase()}%20from%20WeLearn%20online%20class.%20Please%20confirm%20my%20payment%20receipt.`}>Admin Elizabeth</a>, 
                    <a target='_blank' rel='noreferrer' style={{
                    color: "#fff",
                    textDecoration: "none", 
                    position: "relative"}} href={`https://wa.me/2347063649217?text=Hi%2C%20My%20name%20is%20${firstname.toUpperCase()}%20${middlename.toUpperCase()}%20${surname.toUpperCase()}%20from%20WeLearn%20online%20class.%20Please%20confirm%20my%20payment%20receipt.`}>  Admin Erhun</a>
                 </div>
                :
                ""
            }
            <Routes>
                {
                    emailVerified ?
                        paymentVer ?
                            disabled ?
                            <Route path="*" element={
                                <p>
                                    
                                </p>
                            } />
                            :
                            checkLogin()
                        :
                        <>
                            <Route path="*" element={<VerifyPayment setPaymentVer={setPaymentVer} api={props.api} file={props.file} winWidth={props.winWidth} setPaymentPending={setPaymentPending} />} />
                            <Route path="/logout" element={<Logout />} />
                        </>
                    :
                    <>
                        <Route path="*" element={<VerifyEmail email={email} api={props.api} file={props.file} winWidth={props.winWidth} setEmailVerified={setEmailVerified} />} />
                        <Route path="/logout" element={<Logout />} />
                    </>
                    
                }
            </Routes>
        </>
    );
   }
   
export default Appview;